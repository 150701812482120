 /* eslint-disable */
 export default ({ app, store }) => {
    /*
    ** Only run on client-side and only in production mode
    */
    if (process.env.NODE_ENV !== "production") return
    /*
    ** Initialize Facebook Pixel Script
    */
    if (process.browser) {
        if (window.location.host !== 'multiversa.com') return

        window.__lo_site_id = 326986;

    (function() {
      var wa = document.createElement('script'); wa.type = 'text/javascript'; wa.async = true;
      wa.src = 'https://d10lpsik1i8c69.cloudfront.net/w.js';
      var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(wa, s);
      })();
    }
}
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      isup: true,
      pathname: '',
      items: [
        {
          name: 'Home',
          path: '/home',
          width: 57,
        },
        {
          name: 'Graduação',
          path: '/graduacao',
          width: 120,
        },
        {
          name: 'Pós-Graduação',
          path: '/home#pos-graduacao',
          width: 120,
        },
        {
          name: 'Cursos',
          path: '/home#cursos',
          width: 120,
        },
        {
          name: 'Institucional',
          path: '/institucional',
          width: 120,
        },
        {
          name: 'Blog',
          path: '/blog',
          width: 56,
        },
        {
          name: 'Contato',
          path: '/home#contato',
          width: 100,
        },
        {
          name: 'Alunos',
          path: 'https://online.multiversa.com/',
          width: 67,
        },
      ],
      submenus: [
        // { name: 'Início', path: '/' },
        {
          name: 'Graduação Presencial',
          path: '/graduacao-presencial',
          width: 162,
        },
        {
          name: 'Graduação Digital',
          path: '/graduacao-digital',
          width: 150,
        },
        // {
        //   name: 'Pós-Graduação',
        //   path: '/pos-graduacao',
        //   width: 150,
        // },
        // {
        //   name: 'Turismo e Hotelaria',
        //   path: '/portal-de-turismo-e-hotelaria',
        //   width: 160,
        // },
        // {
        //   name: 'Extensão Digital',
        //   path: '/extensao-digital',
        //   width: 150,
        // },
        // {
        //   name: 'Metodologia',
        //   path: '/metodologia',
        //   width: 110,
        // },
      ],
      notVazio: [(v) => !!v || 'Campo obrigatório'],

      emailRules: [
        (v) => !!v || 'Preencha seu e-mail',
        (v) =>
          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(v) ||
          'Digite um e-mail válido.',
      ],
      sended: false,
      loading: false,
      rightDrawer: false,
      onFooter: false,
      tabletAndDown: false,
    }
  },

  methods: {
    contentLoaded() {
      $nuxt.$emit('content-loaded')
    },
  },

  watch: {
    $route(to, from) {
      this.pathname = to.fullPath
      let resolved = this.$router.resolve(this.pathname)
      if (resolved.route.name === null) this.$router.push('/404')
    },
  },

  mounted() {
    this.pathname = location.pathname

    let resolved = this.$router.resolve(this.pathname)
    if (resolved.route.name === null) this.$router.push('/404')

    let txtbanner = document.querySelector('.txt-banner')
    if (txtbanner) {
      txtbanner.style.display = 'none'
    }

    addEventListener('scroll', () => {
      this.isup = scrollY < 200
    })

    this.isup = scrollY < 200

    if (txtbanner) {
      this.$nuxt.$on('content-loaded', () => {
        document.querySelector('.txt-banner').style.display = 'block'
      })
    }
    this.tabletAndDown = screen.availWidth < 769

    addEventListener('resize', () => {
      this.tabletAndDown = screen.availWidth < 769
    })

    setTimeout(() => {
      new RDStationForms(
        'newsletter-multiversa-97bd3e178b47f8a79ead',
        'UA-228576147-1'
      ).createForm()
    }, 500)
  },
}

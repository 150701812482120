export default (_context, inject) => {
    let imagine = (image,  trim = null) => {
        if (process.client) {
            if (image == undefined) return '';
            let url = window.location.host === 'localhost:3000'  || window.location.host === 'http://localhost:3000' ? 'http://localhost:3000/' : 'https://ik.imagekit.io/cyrcl27sn/'
            
            if (trim) {
                let query = `?tr=w-${trim}`
                return url + image + query;
            }
            return url + image;
        } else {
            return ''
        }
    }
    inject('imagine', imagine)
}